import styled from "styled-components";

type ValueProps = {
  print?: boolean;
  width: number;
  bold?: boolean;
};

export const Value = styled.div<ValueProps>`
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: ${(p) => (p.print ? "initial" : "nowrap")};
  max-width: ${(p) => (p.print ? "100%" : p.width + "px")};
  font-weight: ${(p) => (p.bold ? "600" : "normal")};
`;
