import { Grid, Title } from "ui";
import { useContext, useState } from "react";
import { Logo, SearchBar } from "components";
import { Entity } from "types";
import { AppContext, AppContextType } from "contexts";
import NewEntities from "../../pages/LandingPage/NewEntities";
import AmlRanksChange from "../../pages/LandingPage/AmlRanksChange";
import Events from "../../pages/LandingPage/Events";
import { useNavigate } from "react-router-dom";
import { EventType } from "types/Events";
import * as S from "./styled";
export const LandingPage = () => {
  const [isAnimating, setIsAnimating] = useState(false);
  const navigate = useNavigate();

  const navigateToEvent = (eventType: EventType, eventID: number | string) => {
    if (eventType === "Hack") {
      navigate(`/events/hacks/${eventID}`);
    } else if (eventType === "Scam") {
      navigate(`/events/scams/${eventID}`);
    } else if (eventType === "Sanction") {
      navigate(`/events/sanctions/${eventID}`);
    }
  };

  const appContext = useContext<AppContextType>(AppContext);
  const { hacks, scams, sanctions } = appContext.events;
  const entities = appContext.entities.filter((entity: Entity) => entity.description !== undefined);

  return (
    <Grid container direction="column" alignItems="center" style={{ minHeight: "100vh" }}>
      <Grid
        container
        item
        height="440px"
        direction="column"
        alignItems="center"
        paddingTop={10}
        sx={{
          backgroundImage: "url(/images/wallpaper.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Grid item>
          <Logo variant="default" />
        </Grid>

        <Grid item textAlign="center" sx={{ fontSize: "18px" }}>
          Start your crypto diligence journey here
          <br /> by searching for <b>entities</b>, <b>events</b>, or <b>cryptocurrency addresses</b>
        </Grid>
        <Grid container justifyContent="center" item>
          <SearchBar
            placeholder="Search BTC or ETH address, entity..."
            onFocus={() => {
              setIsAnimating(true);
            }}
            onSearchCancel={() => {
              setIsAnimating(false);
            }}
            onBlur={(searchInProgress) => {
              !searchInProgress && setIsAnimating(false);
            }}
            onChange={(searchTerm: string) => {
              // setSearchTerm(searchTerm);
            }}
            options={entities.filter((entity) => entity.status !== "Defunct").map((entity: Entity) => entity.name)}
            value={"searchTerm"}
            animate={isAnimating}
          />
        </Grid>
      </Grid>

      <S.GridWithFlexPadding
        container
        item
        sx={(theme) => {
          return {
            backgroundColor: theme.colors.lightGray,
          };
        }}
        padding="80px 120px 80px 120px"
      >
        <Grid item>
          <Title>Hoptrail News</Title>
        </Grid>
        <Grid container item columnSpacing={2} rowSpacing={2} marginTop="24px">
          <Grid item xs={12} md={6}>
            <NewEntities
              title="Newly Added"
              profiles={entities}
              onEntitySelected={(entityName) => navigate("/entities/" + entityName)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AmlRanksChange
              title="Latest AML Ranking Change"
              profiles={entities}
              onEntitySelected={(entityName) => navigate("/entities/" + entityName)}
            />
          </Grid>
        </Grid>

        <Grid container item marginTop="80px" direction="column" rowGap={3}>
          <Grid item>
            <Title>Latest events</Title>
          </Grid>
          <Events hacks={hacks} scams={scams} sanctions={sanctions} onEventSelected={navigateToEvent} />
        </Grid>
      </S.GridWithFlexPadding>
    </Grid>
  );
};

export default LandingPage;
