import { Link } from "react-router-dom";
import { TableCell } from "ui";
import { Tags, EntityName, Address, HashField } from "components";
import {
  capitalLetter,
  formatCryptoCurrencyValue,
  formatDate,
  formatFiatValue,
  getEntityToDisplay,
  getHigherRiskType,
  isBitcoinAddress,
} from "utils";
import { useContext } from "react";
import { Link as UILink } from "ui";
import AppContext from "contexts/AppContext";

import type { Columns } from "ui";
import { Transaction } from "types";
import { GridRenderCellParams, GridValueGetterParams } from "@mui/x-data-grid";
import { TokenTags } from "../../Events";

type Props = {
  isForPDF?: boolean;
};

export type TransactionWithID = Transaction & { id: number };

const getColumns = ({ isForPDF }: Props = {}): Columns => {
  const { entityTypes } = useContext(AppContext);

  return [
    {
      field: "clientAddress",
      headerName: "Client Address",
      width: 135,
      renderCell: ({ value }) => {
        const coin = isBitcoinAddress(value) ? "btc" : "eth";
        return <Address link={`/report/${coin}/${value}`} address={value} />;
      },
    },
    {
      field: "address",
      headerName: "Risky Address",
      width: 129,
      renderCell: ({ value }) => {
        const coin = isBitcoinAddress(value) ? "btc" : "eth";
        return <Address link={`/report/${coin}/${value}`} address={value} />;
      },
    },

    {
      field: "entityTag",
      headerName: "Tag",
      width: isForPDF ? 150 : 200,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string, TransactionWithID>) => {
        const { entityTag } = getEntityToDisplay(
          {
            entityTag: params.row.entityTag,
            entityType: params.row.entityType,
            entitySubType: params.row.entitySubType,
            controllerTag: params.row.controllerTag,
            controllerType: params.row.controllerType,
            controllerSubType: params.row.controllerSubType,
          },
          entityTypes,
        );
        return (
          <TableCell>
            <b>
              {isForPDF ? (
                <Link to={`/entities/${entityTag}`}>
                  <UILink>{entityTag}</UILink>
                </Link>
              ) : (
                <EntityName name={entityTag} />
              )}
            </b>
          </TableCell>
        );
      },
    },

    {
      field: "type",
      headerName: "Type",
      flex: 1,
      renderCell: (params: GridRenderCellParams<string, TransactionWithID>) => {
        const type = getHigherRiskType(entityTypes, [
          params.row.entityType ?? "",
          params.row.entitySubType ?? "",
          params.row.controllerType ?? "",
          params.row.controllerSubType ?? "",
        ]);
        return type ? <Tags tags={[{ type: type }]} /> : null;
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 100,
      valueFormatter: (params) => (params.value ? formatDate(params.value as string) : null),
    },
    {
      field: "amount",
      headerName: "Amount",
      align: "left",
      width: 125,
      renderCell: (params: GridRenderCellParams<number, TransactionWithID>) => {
        const coin = isBitcoinAddress(params.row.address) ? "btc" : params.row.erc20 ? "erc20" : "eth";
        return formatCryptoCurrencyValue(params.row.amount, coin);
      },
    },
    {
      field: "value",
      headerName: "Value ($)",
      type: "number",
      align: "left",
      width: 120,
      renderCell: ({ row, value }: GridRenderCellParams<number, TransactionWithID>) => {
        const coin = isBitcoinAddress(row.address) ? "btc" : row.isERC20 ? "erc20" : "eth";
        return formatFiatValue(value!, coin);
      },
    },
    {
      field: "symbol",
      headerName: "Asset",
      width: 70,
      align: "center",
      headerAlign: "center",
      renderCell: (params: GridRenderCellParams<string, TransactionWithID>) => {
        return <TokenTags tokens={params.value ? [params.value] : []} />;
      },
    },
    {
      field: "direction",
      headerName: "Direction",
      align: "left",
      width: 95,
      valueFormatter: (params) => capitalLetter(params.value),
    },
    {
      field: "hop",
      headerName: "Hops",
      width: 65,
      align: "center",
      headerAlign: "center",
      valueGetter: (params: GridValueGetterParams<number, TransactionWithID>) => params.row.curHop ?? params.row.hop,
    },
    {
      field: "hash",
      headerName: "Hash",
      width: 75,
      type: "string",
      renderCell: (params: GridRenderCellParams<string, TransactionWithID>) => {
        const chain = isBitcoinAddress(params.row.address) ? "btc" : "eth";
        return <HashField value={params.value} coin={chain} row={params.row} />;
      },
    },
  ];
};

export default getColumns;
