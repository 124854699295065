import { useContext, useEffect, useState } from "react";
import { FormControlLabel, Switch, Link as MUILink, Tooltip } from "@mui/material";
import { LabelValue, Tags, Address, EntityName } from "components";

import { Grid } from "ui";
import AppContext from "contexts/AppContext";
import { formatCryptoCurrencyValue, formatDate, formatFiatValue, getTypeToDisplay } from "utils";

import type { Columns } from "ui";
import * as S from "../styled";
import AuthContext from "contexts/Auth0Context";

// TODO Sync with Transaction Type in BTC and ETH
export type SummaryTransaction = {
  address: string;
  entityTag?: string;
  entityType?: string;
  time?: Date;
  amount: number;
  value: number;
  curHop: number;
  direction: string;
};

type Props = {
  title?: string;
  direction: "incoming" | "outgoing";
  transactions: any[];
  isLargeAddress?: boolean;
  hideAmounts?: boolean;
  chain?: "btc" | "eth" | "erc20";
  originAddress: string;
  onTxCountClick?: (entityTag: string, address: string, hop: number, isBreakdown: boolean) => void;
};

const BitcoinTransactionSummary = ({
  transactions,
  isLargeAddress,
  hideAmounts,
  chain = "btc",
  onTxCountClick,
}: Props) => {
  const { entityTypes } = useContext(AppContext);
  const { user } = useContext(AuthContext);
  const hiddenTags = ["Unknown", "Unknown Addresses", "Returning Funds"];
  const systemTags = ["Unknown", "Unknown Address", "Unknown Addresses", "Returning Funds", "Insignificant Amounts"];
  const [rows, setRows] = useState<any[]>([]);
  const [showBreakdown, setShowBreakdown] = useState(false);
  const [showIndirect, setShowIndirect] = useState(false);
  const [showUnknownEntities, setShowUnknownEntities] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalValue, setTotalValue] = useState(0);

  const DefaultColumns: Columns = [
    {
      field: "entityTag",
      headerName: "Tag",
      minWidth: 150,
      flex: 4,
      renderCell: (params) => {
        return <EntityName name={params.value} />;
      },
    },
    {
      field: "entityType",
      headerName: "Type",
      flex: 3,
      minWidth: 130,
      renderCell: (params) => {
        const typeToDisplay = getTypeToDisplay(entityTypes, params.row);
        return params.value ? (
          <Tags
            tags={[
              {
                type: typeToDisplay,
              },
            ]}
            short
          />
        ) : (
          "Unknown"
        );
      },
    },
    {
      field: "value",
      headerName: "Value ($)",
      align: "left",
      minWidth: 95,
      flex: 2,
      valueFormatter: (params) => formatFiatValue(params.value, chain),
    },
    {
      field: "firstTx",
      headerName: "First Tx",
      align: "left",
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      field: "lastTx",
      headerName: "Last Tx",
      align: "left",
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      field: "txCount",
      headerName: "Tx Count",
      align: "left",
      renderCell: (params) => {
        return onTxCountClick ? (
          <MUILink
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              return onTxCountClick(params.row.entityTag, params.row.address, params.row.curHop, showBreakdown);
            }}
          >
            {params.value}
          </MUILink>
        ) : (
          params.value
        );
      },
    },
    { field: "curHop", headerName: "Hops", maxWidth: 60, flex: 1 },
  ];

  const [columns, setColumns] = useState<Columns>(DefaultColumns);

  // TODO this seems like should be coming from backend

  const showIndirectChange = () => {
    setShowIndirect((prev) => !prev);
  };

  const showBreakdownChange = () => {
    setShowBreakdown((prev) => !prev);
  };

  const showAllChange = () => {
    setShowUnknownEntities((prev) => !prev);
  };

  useEffect(() => {
    const curColumns: Columns = [...DefaultColumns];

    if (showBreakdown) {
      curColumns.unshift({
        field: "address",
        headerName: "Address",
        width: 100,
        renderCell: ({ value }) => {
          const link = `/report/${chain === "erc20" ? "eth" : chain}/${value}`;
          return value ? <Address link={link} address={value?.toString()} /> : "";
        },
      });
    }

    if (!hideAmounts) {
      curColumns.splice(showBreakdown ? 3 : 2, 0, {
        field: "amount",
        headerName: "Amount",
        align: "left",
        minWidth: 95,
        flex: 2,
        valueFormatter: (params) => formatCryptoCurrencyValue(params.value, chain),
      });
    }

    setColumns(curColumns);

    const destinationData = transactions.reduce((prev: any, next: any) => {
      if ((showIndirect && !next.entityType && !next.endOfHop) || (!showIndirect && next.curHop > 1)) {
        return prev;
      }

      const entityTag =
        showBreakdown || next.entityTag === "Unknown"
          ? next.address + next.curHop
          : (next.entityTag || "Unknown") + next.curHop;

      const firstTx = chain === "eth" || chain === "erc20" ? next.date : next.firstDate;
      const lastTx = chain === "eth" || chain === "erc20" ? next.date : next.lastDate;

      if (entityTag in prev) {
        prev[entityTag].amount += next.amount;
        prev[entityTag].value += next.value;
        prev[entityTag].txCount = next.txs ? prev[entityTag].txCount + next.txs.length : prev[entityTag].txCount + 1;
        if (prev[entityTag].firstTx > firstTx) {
          prev[entityTag].firstTx = firstTx;
        }
        if (prev[entityTag].lastTx < lastTx) {
          prev[entityTag].lastTx = lastTx;
        }
      } else {
        prev[entityTag] = {
          ...next,
          firstTx,
          lastTx,
          txCount: next.txs ? next.txs.length : 1,
          entityTag:
            showBreakdown &&
            (!next.entityTag || systemTags.some((unknownEntityTag) => next.entityTag.includes(unknownEntityTag)))
              ? "Unknown"
              : next.entityTag,
        };
      }

      return prev;
    }, {});

    let curRows = Object.keys(destinationData).map((sourceName: any, index: number) => {
      const isKnownEntity =
        destinationData[sourceName].entityTag && destinationData[sourceName].entityTag !== "" ? true : false;
      return {
        id: index,
        ...destinationData[sourceName],
        entityTag: isKnownEntity ? destinationData[sourceName].entityTag : showBreakdown ? "Unknown" : "Hops Exceeded",
      };
    });

    if (!showUnknownEntities) {
      curRows = curRows.filter((row) => !hiddenTags.includes(row.entityTag) && !row.entityTag.startsWith("Unknown"));
    }

    // Sum total amount and total value from curRows
    const { amountSum, valueSum } = curRows.reduce(
      (previousValue, currentValue) => {
        return {
          amountSum: previousValue.amountSum + currentValue.amount,
          valueSum: previousValue.valueSum + currentValue.value,
        };
      },
      {
        amountSum: 0,
        valueSum: 0,
      },
    );

    setRows(curRows);
    if (amountSum === 0 && totalAmount === 0) {
      setShowUnknownEntities(true);
    }
    setTotalAmount(amountSum);
    setTotalValue(valueSum);
  }, [showBreakdown, showUnknownEntities, showIndirect, transactions, hideAmounts]);

  return (
    <Grid container rowSpacing={1}>
      <Grid container item xs={12} md columnSpacing={2}>
        {!hideAmounts && (
          <Grid item>
            <Tooltip disableHoverListener={chain !== "eth"} title="This figure does not include gas fees on sent funds">
              <LabelValue
                value={formatCryptoCurrencyValue(totalAmount, chain) || "0.000"}
                label="Total Amount:"
                direction="row"
                valueBold={true}
              />
            </Tooltip>
          </Grid>
        )}

        <Grid item>
          <LabelValue
            value={formatFiatValue(totalValue, chain)}
            label="Total Value ($):"
            direction="row"
            valueBold={true}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} md="auto" columnSpacing={2}>
        {!isLargeAddress && (
          <Grid item>
            <Tooltip placement="top" title="Toggle between direct and indirect counterparties">
              <span>
                <FormControlLabel
                  control={<Switch size="small" checked={showIndirect} onChange={showIndirectChange} />}
                  label={showIndirect ? <b>Indirect</b> : "Direct"}
                  sx={{
                    marginRight: 0,
                    width: "110px",
                  }}
                />
              </span>
            </Tooltip>
          </Grid>
        )}
        <Grid item>
          <Tooltip placement="top" title="Display all addresses of all sources and destinations">
            <span>
              <FormControlLabel
                control={<Switch size="small" checked={showBreakdown} onChange={showBreakdownChange} />}
                label="Breakdown"
                sx={{
                  fontSize: "14px",
                  marginRight: "0px",
                }}
              />
            </span>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip placement="top" title="Lists all entities and clusters, known and unknown">
            <span>
              <FormControlLabel
                control={<Switch size="small" checked={showUnknownEntities} onChange={showAllChange} />}
                label="All Entities"
                sx={{
                  fontSize: "14px",
                  marginRight: "0px",
                  width: "120px",
                }}
              />
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <S.Table
          fontSize="small"
          columns={columns}
          allowExport={user.isAdmin}
          rows={rows}
          initialState={{
            sorting: {
              sortModel: [{ field: "value", sort: "desc" }],
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default BitcoinTransactionSummary;
