import axios from "axios";
import { useEffect, useState } from "react";
import { Metadata, NationFlag, Summary } from "components";
import { Hack } from "types/Events";
import { TokenTags } from "../TokensTags";
import { EntityDetail } from "../EntityDetail";
import { Divider, Grid, Label, Link, Tag, Title } from "ui";
import { useParams } from "react-router-dom";

const HackDetails = ({ hackId }: { hackId?: string | number }) => {
  const [details, setDetails] = useState<Hack>();
  const params = useParams();

  const hackIDToLoad = hackId || params.hackId;

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(`/api/entities/hacks/${hackIDToLoad}`);
      setDetails(data);
    })();
  }, [hackIDToLoad]);

  if (!details) {
    return <div>Loading...</div>;
  }
  return (
    <Grid container direction="column" rowGap={4}>
      <Grid container rowGap={2} direction="column">
        <Grid>
          <Tag color="orange" label="Hack" backgroundColor="lightOrange" />
        </Grid>
        <Grid>
          <Title>{details?.["Event Description"]}</Title>
          <Label>
            {details &&
              new Date(details.Date).toLocaleString("en-gb", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
          </Label>
        </Grid>
      </Grid>
      <Grid container>
        <Label>Entity</Label>
      </Grid>
      <EntityDetail name={details?.Entity} tag={details?.Type} />
      <Grid container>
        <Metadata
          list={[
            {
              title: "Event",
              value: details?.["Event"],
            },
            {
              title: "Est. Amount Stolen",
              value: details?.["Est. Amount Stolen"]
                ? `$ ${Number(details?.["Est. Amount Stolen"]).toLocaleString("en-gb", {
                    maximumFractionDigits: 2,
                  })}`
                : "-",
            },
            {
              title: "Jurisdiction",
              value: (
                <Grid item container flexWrap="nowrap" columnGap={1}>
                  <Grid item>
                    <NationFlag countryName={details?.["Jurisdiction"]} />
                  </Grid>
                  <Grid item>{details?.["Jurisdiction"]}</Grid>
                </Grid>
              ),
            },
            {
              title: "Perpetrator(s)",
              value: details?.["Perpetrator(s)"],
            },
          ]}
        />
      </Grid>
      <Grid container>
        <Grid container direction="column">
          <Grid>
            <Label>Tokens</Label>
          </Grid>
          <Grid container>
            <TokenTags tokens={details?.Tokens} />
          </Grid>
        </Grid>
      </Grid>
      <Divider />

      <Summary text={details?.Synopsis ?? ""} showAll={true} />
      {details?.Link && (
        <Link href={details?.Link} target="_blank">
          View Source
        </Link>
      )}
    </Grid>
  );
};

export default HackDetails;
