import { Grid, Label, ErrorOutlineIcon, CheckCircleIcon } from "ui";

type Props = {
  highRiskCount: number;
  mediumRiskCount: number;
  addressRisk?: boolean;
};
export const RiskSummary = ({ highRiskCount, mediumRiskCount, addressRisk }: Props) => {
  return (
    <Grid container rowGap={1}>
      <Grid item>
        <Label>Risk</Label>
      </Grid>
      <Grid item container alignItems="center" columnGap={1}>
        <Grid container rowGap={1} direction="column">
          {highRiskCount > 0 && (
            <Grid item container columnGap={1} sx={(theme) => ({ color: theme.colors.red })}>
              <ErrorOutlineIcon />
              <Grid>{highRiskCount} High Risk Transactions Detected</Grid>
            </Grid>
          )}
          {mediumRiskCount > 0 && (
            <Grid item container columnGap={1} sx={(theme) => ({ color: theme.colors.orange })}>
              <ErrorOutlineIcon />
              <Grid>{mediumRiskCount} Medium Risk Transactions Detected</Grid>
            </Grid>
          )}

          {highRiskCount === 0 && mediumRiskCount === 0 && (
            <Grid item container columnGap={1} sx={(theme) => ({ color: theme.colors.green })}>
              <CheckCircleIcon />
              <Grid>No counterparty risks detected</Grid>
            </Grid>
          )}
          {addressRisk && (
            <Grid item container columnGap={1} sx={(theme) => ({ color: theme.colors.red })}>
              <ErrorOutlineIcon color="warning" />
              <Grid>This address is marked as high-risk</Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default RiskSummary;
