import { Grid, Chip } from "ui";
import { useTheme } from "styled-components";
type Props = {
  score: number;
  entityTag?: string;
  value: number | string;
  justifyContent?: string;
  variant?: "expandend" | "compact";
  ignoreScoreColor?: boolean;
};

export const RisktrailScore = ({
  score,
  value,
  entityTag,
  variant = "expandend",
  justifyContent,
  ignoreScoreColor,
}: Props) => {
  const theme: Theme = useTheme() as Theme;
  const { lightGreen, orange, green, lightOrange, darkGreen, red, darkGray, lightGray, white } = theme.colors;

  const getScoreColors = (score: number, isWallet: boolean) => {
    if (ignoreScoreColor) {
      return {
        backgroundColor: lightGreen,
        color: green,
      };
    }
    type ScoreColors = {
      color: string;
      backgroundColor: string;
      threshold: number;
    };

    const thresholdFactor = isWallet ? 1 : 10;
    const thresholds: ScoreColors[] = [
      {
        threshold: 0,
        backgroundColor: lightGray,
        color: darkGray,
      },
      {
        threshold: 2 * thresholdFactor,
        color: white,
        backgroundColor: red,
      },
      {
        threshold: 4 * thresholdFactor,
        color: white,
        backgroundColor: red,
      },
      {
        threshold: 6 * thresholdFactor,
        color: orange,
        backgroundColor: lightOrange,
      },
      {
        threshold: 8 * thresholdFactor,
        backgroundColor: lightGreen,
        color: darkGreen,
      },
      {
        threshold: 10 * thresholdFactor,
        backgroundColor: green,
        color: white,
      },
    ];
    const scoreColors =
      thresholds.find(({ threshold }) => {
        return score <= threshold;
      }) || thresholds[thresholds.length - 1];

    const { backgroundColor, color } = scoreColors;

    return {
      color,
      backgroundColor,
    };
  };

  const colors = getScoreColors(score, entityTag === "Wallet");
  return (
    <Grid container justifyContent={justifyContent}>
      {variant === "compact" ? (
        <Chip
          label={value}
          variant="outlined"
          size="small"
          customBackground={colors.backgroundColor}
          customColor={colors.color}
        />
      ) : (
        <Chip
          label={value}
          // size="small"
          customBackground={colors.backgroundColor}
          customColor={colors.color}
          sx={{
            width: "100%",
            fontWeight: "bold",
          }}
        />
        // <Grid container justifyContent={justifyContent}>
        //   <S.RisktrailScore
        //     bgcolor={colors.backgroundColor}
        //     color={colors.color}
        //   >
        //     {value}
        //   </S.RisktrailScore>
        // </Grid>
      )}
    </Grid>
  );
};

export default RisktrailScore;
