import { AppContext } from "contexts/AppContext";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Title, Label, Box, Grid, Chip } from "ui";
import EntitiesList from "./EntitiesList";

export const EntityProfiles = () => {
  const appContext = useContext(AppContext);
  const [activeCategories, setActiveCategories] = useState<string>("All");
  const { category } = useParams();
  const navigate = useNavigate();

  const entities = appContext.entities
    .sort((a, b) => (b.riskScore || 0) - (a.riskScore || 0))

    .map((profile) => ({
      rank: category ? profile.tagRank : profile.globalRank,
      ...profile,
    }));
  const [activeEntities, setActiveEntities] = useState<any[]>(entities);

  let entityTags = entities.map((entity) => entity.tag).filter((tag) => tag !== "Wallet");
  entityTags = [...new Set(entityTags)];
  entityTags.unshift("All");

  const showEntity = (entityName: string) => {
    navigate(`/entities/${entityName}`);
  };

  const updateActiveCategories = (category: string) => {
    const newEntities = appContext.entities
      .filter((entity) => {
        if (category === "All") return true;
        return category === entity.tag && entity.tag !== "Wallet";
      })
      .sort((a, b) => (b.riskScore || 0) - (a.riskScore || 0))
      .map((profile) => ({
        rank: profile.globalRank,
        ...profile,
      }));

    setActiveCategories(category);
    setActiveEntities(newEntities);
  };

  return (
    <Grid container direction="column" rowGap={3}>
      <Box>
        <Title>Leaderboards</Title>
        <Label>Entities ranked against Hoptrail’s proprietary Anti-Money Laundering (AML) scoring system.</Label>
      </Box>
      <Grid container rowGap={2} columnGap={1}>
        {entityTags.map((tag) => (
          <Chip
            key={tag}
            label={tag}
            sx={(theme) => ({
              fontSize: "14px",
              backgroundColor: activeCategories.includes(tag) ? theme.colors.primary : theme.colors.white,
              color: activeCategories.includes(tag) ? theme.colors.white : theme.colors.darkGray,
              border: activeCategories.includes(tag) ? "none" : `1px solid ${theme.colors.gray}`,
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.background.default,
              },
            })}
            clickable
            onClick={() => updateActiveCategories(tag)}
          />
        ))}
      </Grid>
      <EntitiesList
        rows={activeEntities}
        display={activeCategories === "All" ? "All" : "Category"}
        onEntitySelected={(entity) => showEntity(entity.name)}
      />
    </Grid>
  );
};

export default EntityProfiles;
