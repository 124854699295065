import { Entity } from "types";
import { SealIcon, FileDownloadIcon, Grid, Avatar, Button, Tag, Title, IconButton, Label } from "ui";
import alamedaIcon from "./Alameda Research.png";
import NotificationsPopover from "./NotificationsPopover";

type Props = {
  entity: Entity;
  isSmallScreen: boolean;
  handlePrint?: () => void;
};

export const BasicInfo = ({ entity, handlePrint, isSmallScreen }: Props) => {
  const logoPath = entity.name === "Alameda Research" ? alamedaIcon : `/images/entities/${entity.name}.png`;
  return (
    <Grid container item justifyContent="space-between">
      <Grid container item columnGap={3} flexWrap="nowrap">
        <Grid container item width="fit-content">
          <Avatar size="large" src={logoPath} />
        </Grid>
        <Grid container item sm>
          <Grid container columnGap={2} justifyContent="space-between" flexWrap="nowrap">
            <Grid container alignItems="center" columnGap={1} flexWrap="nowrap">
              <Grid>
                <Title>{entity.name}</Title>
              </Grid>
              <Grid>
                <Tag
                  label={entity.status}
                  color={entity.status === "Active" ? "green" : "darkGray"}
                  size="medium"
                  backgroundColor={entity.status === "Active" ? "lightGreen" : "lightGray"}
                />
              </Grid>
              {entity.verified && (
                <Grid>
                  <Tag
                    icon={<SealIcon />}
                    label={"Verified"}
                    color="primary"
                    size="medium"
                    backgroundColor={"lightPrimary"}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container item justifyContent={isSmallScreen ? "initial" : "flex-end"} alignItems={"center"}>
              <Grid item>
                <NotificationsPopover entityID={entity.id} />
              </Grid>
              {isSmallScreen && handlePrint && (
                <Grid item>
                  <IconButton onClick={handlePrint}>
                    <FileDownloadIcon />
                  </IconButton>
                </Grid>
              )}

              {!isSmallScreen && handlePrint && (
                <Grid item>
                  <Button onClick={handlePrint} icon={<FileDownloadIcon />} color="secondary" variant="outlined">
                    Download Report
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item>
            <Label>
              {entity.tag}
              {entity.subTags ? ` - ${entity.subTags}` : ""}
            </Label>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BasicInfo;
