import { Divider, Link } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Label, Tag, Title } from "ui";
import { Metadata, NationFlag, Summary } from "components";
import { Sanction } from "../../../types/Events";
import { TokenTags } from "../TokensTags";

const SanctionDetail = ({ sanctionId }: { sanctionId?: string | number }) => {
  const [details, setDetails] = useState<Sanction>();
  const params = useParams();

  const sanctionIDToLoad = sanctionId || params.sanctionId;
  useEffect(() => {
    (async () => {
      const { data } = await axios.get(`/api/entities/sanctions/${sanctionIDToLoad}`);
      setDetails(data);
    })();
  }, [sanctionIDToLoad]);
  return (
    <Grid container direction="column" rowGap={4}>
      <Grid container rowGap={2} direction="column">
        <Grid>
          <Tag color="white" backgroundColor="red" label="Sanction" />
        </Grid>
        <Grid>
          <Title>{details?.["Subject"]}</Title>
          <Label>
            {details &&
              new Date(details.Date).toLocaleString("en-gb", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}
          </Label>
        </Grid>
      </Grid>
      <Grid container>
        <Metadata
          list={[
            {
              title: "Type",
              value: details?.["Type"],
            },
            {
              title: "Sub-Type",
              value: details?.["Sub-Type"],
            },

            {
              title: "Jurisdiction",
              value: details?.["Jurisdiction"] && (
                <>
                  {details?.["Jurisdiction"].map((jurisdiction) => (
                    <Grid item container flexWrap="nowrap" key={jurisdiction} columnGap={1}>
                      <Grid item>
                        <NationFlag countryName={jurisdiction} />
                      </Grid>
                      <Grid item>{jurisdiction}</Grid>
                    </Grid>
                  ))}
                </>
              ),
            },
            {
              title: "Enforcement Body",
              value: details?.["Enforcement Body"],
            },
          ]}
        />
      </Grid>
      <Grid container>
        <Grid container direction="column">
          <Grid>
            <Label>Tokens</Label>
          </Grid>
          <Grid container>
            <TokenTags tokens={details?.Tokens} />
          </Grid>
        </Grid>
      </Grid>
      <Divider />

      <Summary text={details?.Synopsis ?? ""} showAll={true} />
      {details?.Link && (
        <Link href={details?.Link} target="_blank">
          View Source
        </Link>
      )}
    </Grid>
  );
};

export default SanctionDetail;
