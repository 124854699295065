import * as React from "react";

const useIsOverflow = (ref: React.RefObject<HTMLElement> | null, callback?: (arg: boolean) => void) => {
  const [isOverflow, setIsOverflow] = React.useState(false);

  React.useLayoutEffect(() => {
    const current = ref?.current;

    const trigger = () => {
      if (!current) {
        return;
      }

      const hasOverflow = current.scrollWidth > current.clientWidth;

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    if (current) {
      if ("ResizeObserver" in window) {
        new ResizeObserver(trigger).observe(current);
      }

      trigger();
    }
  }, [callback, ref]);

  return isOverflow;
};

export default useIsOverflow;
