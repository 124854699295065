import { Avatar, Grid, RisktrailScore, ArrowDownwardIcon, ArrowUpwardIcon, Chip } from "ui";
import { Tags, Checkmark, NationFlag } from "components";
import type { Columns } from "ui";

const getColumns = (display: "All" | "Category"): Columns => {
  return [
    {
      field: "rank",
      headerName: display === "All" ? "Rank" : "Rank",
      maxWidth: 80,
      headerClassName: "rankHeader",
      renderCell: ({ value, row }) => {
        const rank = display === "All" ? row.globalRank : row.tagRank;
        return <Grid padding={1}>{rank}</Grid>;
      },
    },
    {
      field: "name",
      headerName: "Entity",
      minWidth: 300,
      flex: 1,
      renderCell: ({ value, row }) => {
        return (
          <Grid container columnGap={1} wrap="nowrap">
            <Avatar src={`/images/entities/${value}.png`} size="small"></Avatar>
            <Grid container item overflow="hidden" textOverflow="ellipsis" flexWrap="nowrap" columnGap={2}>
              <Grid item>
                <b>{value} </b>
              </Grid>
              <Grid item>
                {row.isNew && (
                  <Tags
                    short={false}
                    tags={[
                      {
                        type: "New",
                      },
                    ]}
                  />
                )}
              </Grid>
              {row.riskScoreTrend !== "none" &&
                (row.riskScoreTrend === "up" ? (
                  <Grid container alignItems="center" columnGap={1}>
                    <ArrowUpwardIcon color="success" />
                    <Chip
                      size="small"
                      color="success"
                      variant="outlined"
                      label={(row.riskScore - row.previousScore).toFixed(2)}
                    />
                  </Grid>
                ) : (
                  <Grid container alignItems="center" columnGap={1}>
                    <ArrowDownwardIcon color="error" />
                    <Chip
                      size="small"
                      color="error"
                      variant="outlined"
                      label={(row.previousScore - row.riskScore).toFixed(2)}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        );
      },
    },
    {
      field: "tag",
      headerName: "Type",
      minWidth: 160,
    },
    {
      field: "riskScore",
      type: "number",
      headerName: "AML Score",

      align: "center",
      minWidth: 120,
      headerAlign: "center",
      renderCell: ({ value, row }) =>
        value && (
          <Grid container>
            <RisktrailScore
              score={value}
              value={value.toFixed(2)}
              entityTag={row.tag}
              variant="compact"
              justifyContent="center"
            />
          </Grid>
        ),
    },
    {
      field: "solvencyScore",
      headerName: "Solvency Transparency",
      align: "center",
      headerAlign: "center",
      minWidth: 230,
      renderCell: ({ value, row }) =>
        value !== null && (
          <Grid container>
            <RisktrailScore
              score={value}
              value={value + "%"}
              entityTag={row.tag}
              variant="compact"
              justifyContent="center"
              ignoreScoreColor={value !== 0}
            />
          </Grid>
        ),
    },
    {
      field: "jurisdiction",
      headerName: "Jurisdiction",
      minWidth: 140,
      renderCell: (params) => {
        return (
          <Grid item container flexWrap="nowrap" columnGap={1}>
            <Grid item>
              <NationFlag countryName={params.value} />
            </Grid>
            <Grid item>{params.value}</Grid>
          </Grid>
        );
      },
    },
    // {
    //   field: "founded",
    //   headerName: "Established",
    //   minWidth: 150,
    // },
    {
      field: "penaltyPoints",
      align: "center",
      headerName: "Penalty",
      minWidth: 100,
      renderCell: ({ value }) => <Checkmark value={value || false} icon={value ? "important" : "none"} />,
    },
    {
      field: "amlPolicy",
      align: "center",
      headerName: "AML",
      width: 70,
      renderCell: ({ value }) => (
        <Checkmark value={value} icon={["Partial", "Unclear"].includes(value) ? "partial" : "checkmark"} />
      ),
    },
    {
      field: "KYC",
      align: "center",
      headerName: "KYC",
      minWidth: 70,
      maxWidth: 70,
      renderCell: ({ value }) => <Checkmark value={value || false} />,
    },
    {
      field: "licensed",
      align: "center",
      headerName: "Regulated",
      minWidth: 120,
      renderCell: ({ value }) => <Checkmark value={value || false} />,
    },
    // {
    //   field: "privacyCoins",
    //   align: "center",
    //   headerName: "Privacy Coins",
    //   minWidth: 150,
    //   renderCell: ({ value }) => (
    //     <Checkmark value={value || false} icon="important" />
    //   ),
    // },
  ];
};

export default getColumns;
