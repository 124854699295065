import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Grid, CloseIcon, Title, IconButton } from "ui";

type Props = {
  confirmText?: string;
  children: React.ReactNode[] | React.ReactNode | string;
  onClose: () => void;
  open: boolean;
  title?: string;
  fitContent?: boolean;
};

const ModalDialog = ({ children, onClose, open, title = "", fitContent = false }: Props) => (
  <Dialog
    open={open}
    onClose={onClose}
    disableScrollLock
    PaperProps={
      fitContent
        ? undefined
        : {
            sx: {
              minHeight: "95vh",
              minWidth: "95vw",
            },
          }
    }
  >
    <DialogContent>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Title>{title}</Title>
        </Grid>
        <Grid item>
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Grid>
      </Grid>
      {children}
    </DialogContent>
  </Dialog>
);

export default ModalDialog;
