import { Tooltip } from "@mui/material";
import useIsOverflow from "hooks/useIsOverflow";
import React from "react";
import { Colors } from "styles/variables";
import { Grid, Label } from "ui";
import * as S from "./styled";

type Props = {
  label: string;
  value: React.ReactNode | string | string[];
  direction?: "column" | "row";
  alignItems?: string;
  print?: boolean;
  bold?: boolean;
  valueBold?: boolean;
  width?: number;
  justifyContent?: string;
  labelColor?: keyof Colors;
};

const LabelValue = ({
  label,
  value = "-",
  bold = false,
  direction = "column",
  print = false,
  width = 130,
  labelColor,
  valueBold,
  justifyContent,
}: Props) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const renderValue = () => {
    if (Array.isArray(value) && value.length > 1) {
      return value.map((item, index) => (
        <React.Fragment key={index}>
          {item}
          <br />
        </React.Fragment>
      ));
    }
    return value;
  };

  const isOverflow = useIsOverflow(ref);
  return (
    <Grid
      container
      direction={direction}
      columnSpacing={1}
      rowGap={Array.isArray(value) && value.length > 1 ? 1 : 0}
      alignItems={direction === "row" ? "center" : undefined}
      justifyContent={justifyContent ? justifyContent : direction === "row" ? "flex-end" : undefined}
    >
      <Grid item>
        <Label color={labelColor || "darkGray"} size="small">
          {bold ? <b>{label}</b> : label}
        </Label>
      </Grid>
      <Grid item>
        {isOverflow && !print ? (
          <Tooltip placement="right" title={Array.isArray(value) ? value.join(", ") : (value as string)}>
            <S.Value ref={ref} width={width} bold={valueBold}>
              {renderValue()}
            </S.Value>
          </Tooltip>
        ) : (
          <S.Value ref={ref} print={print} width={width} bold={valueBold}>
            {renderValue()}
          </S.Value>
        )}
      </Grid>
    </Grid>
  );
};

export default LabelValue;
