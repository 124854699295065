import { DeleteIcon, Flag, ActionCell, Tooltip } from "ui";
import { Address, EntityName, Tags } from "components";
import { isBitcoinAddress, formatDate, formatCryptoCurrencyValue, getEntityToDisplay, getTypeToDisplay } from "utils";
import { EntityType, Report } from "types";
import type { Columns } from "ui";
import { GridRenderCellParams, GridRowParams } from "@mui/x-data-grid";
import { TokenTags } from "../../Events";
import { Report as EthReport } from "types/ethereum";

const getFlag = (row: ReportWithId) => {
  const highRiskTransactions = Array.isArray(row.highRisks)
    ? row.highRisks
    : [...row.highRisks.incoming, ...row.highRisks.outgoing];
  if (highRiskTransactions.length === 0) {
    return <Flag value="LOW" />;
  }

  const flags = highRiskTransactions.map((tx) => (tx.hop === 1 || tx.curHop == 1 ? "Direct" : "Indirect"));

  return (
    <Tooltip title={[...new Set(flags)].join(", ")}>
      <span>
        <Flag value="HIGH" />
      </span>
    </Tooltip>
  );
};

export type ReportWithId = (Report & { id: number }) | (EthReport & { id: number });

const getColumns = (onAddressDelete: (address: string) => void, entityTypes: EntityType[]): Columns => {
  return [
    {
      field: "address",
      headerName: "Address",
      align: "left",
      width: 95,
      renderCell: (params: GridRenderCellParams<string, ReportWithId>) => {
        const address = params.row.addressInfo.address;
        const coin = isBitcoinAddress(address) ? "btc" : "eth";
        return <Address link={`/report/${coin}/${address}`} address={address} />;
      },
    },
    {
      field: "cryptoCurrency",
      headerName: "Asset",
      align: "left",
      width: 70,
      renderCell: (params: GridRenderCellParams<string, ReportWithId>) => {
        return <TokenTags tokens={params.value ? [params.value] : []} />;
      },
    },
    {
      field: "entityName",
      headerName: "Tag",
      align: "left",
      flex: 1,
      renderCell: (params: GridRenderCellParams<string, ReportWithId>) => {
        const { entityTag } = getEntityToDisplay(
          {
            ...params.row.addressInfo,
            entityTag: params.row.addressInfo.entityName,
          },
          entityTypes,
        );
        return (
          <span>
            <EntityName name={entityTag} />
          </span>
        );
      },
    },
    {
      field: "type",
      headerName: "Type",
      align: "left",
      flex: 1,
      renderCell: (params: GridRenderCellParams<string, ReportWithId>) => {
        const typeToDisplay = getTypeToDisplay(entityTypes, {
          entityType: params.row.addressInfo.entityTag ?? null,
          entitySubType: params.row.addressInfo.entitySubTag ?? null,
          controllerType: params.row.addressInfo.controllerType ?? null,
          controllerSubType: params.row.addressInfo.controllerSubType ?? null,
        });
        return typeToDisplay !== "Unknown" ? <Tags tags={[{ type: typeToDisplay }]} /> : "Unknown";
      },
    },
    {
      field: "balance",
      headerName: "Balance",
      align: "left",
      width: 90,
      renderCell: (params: GridRenderCellParams<number, ReportWithId>) => {
        const coin = isBitcoinAddress(params.row.addressInfo.address) ? "btc" : "eth";
        return <span>{formatCryptoCurrencyValue(params.row.addressInfo.balance, coin)}</span>;
      },
    },
    {
      field: "received",
      headerName: "Total Received",
      align: "left",
      width: 140,
      renderCell: (params: GridRenderCellParams<number, ReportWithId>) => {
        const coin = isBitcoinAddress(params.row.addressInfo.address) ? "btc" : "eth";
        return <span>{formatCryptoCurrencyValue(params.row.addressInfo.received, coin)}</span>;
      },
    },
    {
      field: "firstTx",
      headerName: "First Tx",
      align: "left",
      width: 90,
      renderCell: (params: GridRenderCellParams<string, ReportWithId>) => {
        return <span>{formatDate(params.row.addressInfo.firstTx)}</span>;
      },
    },
    {
      field: "lastTx",
      headerName: "Last Tx",
      align: "left",
      width: 90,
      renderCell: (params: GridRenderCellParams<string, ReportWithId>) => {
        return <span>{formatDate(params.row.addressInfo.lastTx)}</span>;
      },
    },
    {
      field: "txCount",
      headerName: "Tx Count",
      align: "left",
      width: 95,
      renderCell: (params: GridRenderCellParams<number, ReportWithId>) => {
        return <span>{params.row.addressInfo.txCount}</span>;
      },
    },
    {
      field: "flag",
      headerName: "Flag",
      align: "center",
      width: 65,
      renderCell: (params: GridRenderCellParams<unknown, ReportWithId>) => {
        return <span>{getFlag(params.row)}</span>;
      },
    },
    {
      field: "data_created_at",
      headerName: "Created",
      align: "left",
      width: 90,
      renderCell: ({ value }) => {
        return <span>{formatDate(value)}</span>;
      },
    },

    {
      field: "actions",
      type: "actions",
      width: 55,
      getActions: (params: GridRowParams<ReportWithId>) => [
        <ActionCell
          icon={<DeleteIcon />}
          key={12}
          onClick={() => onAddressDelete(params.row.addressInfo.address)}
          label="Delete"
          placeholder={"Delete"}
        />,
      ],
    },
  ];
};

export default getColumns;
