import styled from "styled-components";
import { Chip as UIChip } from "ui";
import type { EntityTypeRisk } from "types";
import { default as ErrorIconUI } from "@mui/icons-material/Error";
type ChipProps = {
  $profile: EntityTypeRisk;
  theme: Theme;
  $noColor?: boolean;
};

type DangerIconProps = {
  theme: Theme;
  $noColor?: boolean;
};

const getChipStyles = ({ $profile, $noColor, theme }: ChipProps) => {
  if ($noColor) {
    return {
      color: theme.colors.darkGray,
    };
  }

  if ($profile === "HIGH" || $profile === "VERY_HIGH") {
    return {
      // background: theme.colors.lightRed,
      background: "#FFEBEB",
      color: theme.colors.red,
    };
  }

  if ($profile === "MEDIUM") {
    return {
      background: theme.colors.orange,
      color: theme.colors.white,
    };
  }

  return {
    background: theme.colors.lightGreen,
    color: theme.colors.green,
  };
};

export const Chip = styled(UIChip)`
  ${(props: ChipProps) => getChipStyles(props)};

  font-weight: bold;
  height: 24px;
`;

export const ErrorIcon = styled(ErrorIconUI)<DangerIconProps>`
  fill: ${(props) => (props.$noColor ? props.theme.colors.darkGray : (props.theme.colors.red as string))};
`;
