import React from "react";
import { Grid, Title, Subtitle, IconButton, DeleteIcon, ContentCopyIcon, Tooltip } from "ui";
import { ApiKey } from "./Api"; // Assuming the ApiKey type is exported from the main component

interface ApiKeyItemProps {
  apiKey: ApiKey;
  newlyCreatedKey: ApiKey | null;
  onDelete: (id: string) => void;
  onCopy: (key: string) => void;
}

const ApiKeyItem: React.FC<ApiKeyItemProps> = ({ apiKey, newlyCreatedKey, onDelete, onCopy }) => {
  const isKeyExpired = (expiresAt: string): boolean => {
    return new Date(expiresAt) < new Date();
  };

  return (
    <Grid
      container
      sx={{
        border: (theme) => `1px solid ${theme.colors.gray}`,
        borderRadius: "12px",
        padding: 2,
        opacity: (apiKey.expiresAt && isKeyExpired(apiKey.expiresAt)) || apiKey.isRevoked ? 0.5 : 1,
        backgroundColor:
          (apiKey.expiresAt && isKeyExpired(apiKey.expiresAt)) || apiKey.isRevoked
            ? "rgba(0, 0, 0, 0.05)"
            : "transparent",
        minHeight: "114px",
      }}
      alignItems="center"
    >
      <Grid item xs={10}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Tooltip title={apiKey.name}>
              <Title variant="small">{apiKey.name}</Title>
            </Tooltip>
          </Grid>
          <Grid item>
            <Subtitle variant="small">
              Expires: {apiKey.expiresAt ? new Date(apiKey.expiresAt).toLocaleDateString() : "Never"}
            </Subtitle>
          </Grid>
          {apiKey.expiresAt && isKeyExpired(apiKey.expiresAt) && (
            <Grid item>
              <Subtitle variant="small" color="error">
                Expired
              </Subtitle>
            </Grid>
          )}
          {apiKey.isRevoked && (
            <Grid item>
              <Subtitle variant="small" color="error">
                Revoked
              </Subtitle>
            </Grid>
          )}
          {newlyCreatedKey && newlyCreatedKey.id === apiKey.id && (
            <Grid item sx={{ width: "100%", overflow: "hidden" }}>
              <Tooltip title={newlyCreatedKey.apiKey || ""}>
                <Subtitle variant="small" color="secondary">
                  {newlyCreatedKey.apiKey}
                </Subtitle>
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={2} container direction="column" justifyContent="center" alignItems="flex-end" spacing={1}>
        <Grid item>
          <IconButton onClick={() => onDelete(apiKey.id)}>
            <DeleteIcon />
          </IconButton>
        </Grid>
        {newlyCreatedKey && newlyCreatedKey.id === apiKey.id && (
          <Grid item xs={2} container direction="column" justifyContent="center" alignItems="flex-end" spacing={1}>
            <Grid item>
              <IconButton
                onClick={() => onCopy(newlyCreatedKey.apiKey || "")}
                sx={{
                  paddingBottom: 0,
                  marginBottom: "-4px",
                }}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ApiKeyItem;
