import { AppContext } from "contexts/AppContext";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import type { Entity } from "types";
import EntityProfile from "components/EntityProfile";

export const EntityProfileDetail = () => {
  const appContext = useContext(AppContext);
  const { entityName } = useParams();
  const entityDetail = appContext.entities.find((entity) => entity.name === entityName) as Entity;

  return <div>{entityDetail && <EntityProfile entity={entityDetail} />}</div>;
};

export default EntityProfileDetail;
