import React, { useState } from "react";
import { Divider, List, ListItem, ListItemAvatar, ListItemText, Pagination } from "@mui/material";
import { BitcoinIcon, SearchIcon, Label, EthereumIcon } from "ui";
import { useNavigate } from "react-router-dom";
import * as S from "./styled";

type Props = {
  history: any[];
};

export const SearchHistoryList: React.FC<Props> = ({ history }) => {
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const bitcoinAddressRegex = new RegExp("^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,62}$");

  if (history.length === 0) {
    return <Label size="normal">No searches found.</Label>;
  }

  return (
    <>
      <List
        sx={{
          width: "100%",
          padding: 0,
          bgcolor: "background.paper",
        }}
      >
        <Divider />
        {history.slice((page - 1) * 10, 10 * page).map((item: any, index) => {
          const chain = bitcoinAddressRegex.test(item.value) ? "btc" : "eth";

          return (
            <>
              {index ? <Divider /> : null}
              <ListItem key={item.id} sx={{ flexWrap: "wrap" }}>
                <ListItemAvatar>
                  <S.IconWrapper>{chain === "btc" ? <BitcoinIcon /> : <EthereumIcon />}</S.IconWrapper>
                </ListItemAvatar>
                <ListItemText
                  primary={item.value}
                  secondary={new Date(item._max.created_at).toLocaleString()}
                  sx={{
                    marginBottom: "0px",
                  }}
                />

                <S.SearchButton
                  onClick={() => {
                    navigate(`/report/${chain}/${item.value}`);
                  }}
                >
                  <SearchIcon />
                  Search Again
                </S.SearchButton>
              </ListItem>
            </>
          );
        })}
        <Divider />
      </List>
      <Pagination page={page} onChange={(e, p) => setPage(p)} count={Math.ceil(history.length / 10)} color="primary" />
    </>
  );
};
