import { Entity } from "types";
import { RisktrailScore, Grid, Chip, Label } from "ui";

type Props = {
  entity: Entity;
};

export const Stats = ({ entity }: Props) => (
  <Grid container item columnGap={2} flexWrap="wrap" rowGap={2}>
    {entity.riskScore !== null && (
      <Grid container direction="column" rowGap={1} width="180px">
        <Label>AML score</Label>
        <RisktrailScore score={entity.riskScore} value={entity.riskScore.toFixed(2)} entityTag={entity.tag} />
      </Grid>
    )}

    {entity.solvencyScore !== null && (
      <Grid container direction="column" rowGap={1} width="180px">
        <Label>Solvency Transparency</Label>
        <RisktrailScore
          score={entity.solvencyScore}
          value={entity.solvencyScore + "%"}
          justifyContent="center"
          ignoreScoreColor={true}
        />
      </Grid>
    )}

    {entity.tagRank && entity.status !== "Defunct" && (
      <Grid container direction="column" sm={6} rowGap={1}>
        <Label>Category Rank</Label>
        <Chip
          label={`#${entity.tagRank}`}
          sx={(theme) => ({
            fontWeight: "bold",
            backgroundColor: theme.colors.lightGray,
            color: theme.colors.black,
            width: "80px",
          })}
        />
      </Grid>
    )}
  </Grid>
);

export default Stats;
