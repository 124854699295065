import { BitcoinIcon, EthereumIcon, Grid, SearchIcon } from "ui";
import { isBitcoinAddress } from "utils";

export type SearchHistoryItemType = {
  id: string;
  value: string;
  name: string;
  type: "entity" | "address";
};

type Props = {
  item: SearchHistoryItemType;
  onClick?: () => void;
};

export const SearchHistoryItem = ({ item, onClick }: Props) => {
  const { value } = item;
  const isBTCAddress = isBitcoinAddress(value);
  return (
    <Grid container item xs={12} columnSpacing={2} flexWrap="nowrap" onClick={onClick}>
      <Grid item>
        <SearchIcon />
      </Grid>
      <Grid container item xs={12} columnSpacing={1} flex-wrap="nowrap">
        <Grid item>{isBTCAddress ? <BitcoinIcon size={24} /> : <EthereumIcon size={24} />}</Grid>
        <Grid flex={1} container item alignItems="flex-start">
          {value}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default SearchHistoryItem;
