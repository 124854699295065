import { RouteObject } from "react-router-dom";
import LandingPage from "pages/LandingPage";
import { AppLayout, LandingPageLayout, DetailLayout } from "layouts";
import {
  Address,
  EntityProfileDetail,
  EntityProfiles,
  Events,
  Profile,
  SearchHistory,
  Portfolio,
  Guide,
  HackDetail,
  SanctionDetail,
  BitcoinReport,
  EthereumReport,
  ScamDetail,
  Api,
} from "pages";
import Notifications from "../pages/Notifications/Notifications";
import ProtectedRoute from "./ProtectedRoute";

const landingPageRoute: RouteObject = {
  path: "/",
  element: <LandingPageLayout />,
  children: [
    {
      path: "/",
      element: <LandingPage />,
    },
  ],
};

const detailPages: RouteObject = {
  path: "/",
  element: <DetailLayout />,
  children: [
    {
      path: "events/sanctions/:sanctionId",
      element: <SanctionDetail />,
    },
    {
      path: "events/hacks/:hackId",
      element: <HackDetail />,
    },
    {
      path: "events/scams/:scamId",
      element: <ScamDetail />,
    },
    {
      path: "entities/:entityName",
      element: <EntityProfileDetail />,
    },
  ],
};

const otherRoutes: RouteObject[] = [
  {
    path: "report/btc/:address",
    element: <BitcoinReport />,
  },
  {
    path: "report/eth/:address/:cache",
    element: <EthereumReport />,
  },
  {
    path: "report/eth/:address",
    element: <EthereumReport />,
  },
  {
    path: "address",
    element: <Address />,
  },
  {
    path: "history",
    element: <SearchHistory />,
  },
  {
    path: "profile",
    element: <Profile />,
  },
  {
    path: "alerts",
    element: <Notifications />,
  },
  {
    path: "portfolio",
    element: <Portfolio />,
  },
  {
    path: "guide",
    element: <Guide />,
  },
  {
    path: "events/:event",
    element: <Events />,
  },
  {
    path: "/leaderboard",
    element: <EntityProfiles />,
  },
];

const protectedApiRoute: RouteObject = {
  element: <ProtectedRoute requireApiAccess />,
  children: [
    {
      path: "tokens",
      element: <Api />,
    },
  ],
};

const AdminRoutes: RouteObject[] = [
  landingPageRoute,
  detailPages,
  ...otherRoutes.map((route) => ({
    ...route,
    element: <AppLayout>{route.element}</AppLayout>,
  })),
  {
    ...protectedApiRoute,
    element: <AppLayout>{protectedApiRoute.element}</AppLayout>,
  },
];

export default AdminRoutes;
