import { parseISO } from "date-fns";
import { utcToZonedTime, format } from "date-fns-tz";

export const utcToLondonTime = (dateString: string) => {
  const pattern = "dd/MM/yyyy HH:mm";
  const iso = parseISO(dateString);
  const maybe = new Date(Date.UTC(iso.getFullYear(), iso.getMonth(), iso.getDate(), iso.getHours(), iso.getMinutes()));

  const londonTime = utcToZonedTime(maybe, "Europe/London");

  return format(londonTime, pattern);
};

export const formatDate = (dateString: string) => {
  if (!dateString) {
    return "";
  }

  return new Date(dateString).toLocaleDateString("en-gb").replaceAll("/", "-");
};

export const formatUTCDateWithTimezone = (dateStr: string, timeZone = "UTC") => {
  const pattern = "dd/MM/yyyy HH:mm";
  const date = parseISO(dateStr);
  const zonedDate = utcToZonedTime(date, timeZone);
  return format(zonedDate, pattern);
};
