import { useContext, useEffect, useState } from "react";
import { FormControlLabel, Switch, Tooltip } from "@mui/material";
import { LabelValue } from "components";
import { Grid } from "ui";
import { formatCryptoCurrencyValue, formatFiatValue } from "utils";
import getColumns from "./columns";
import AuthContext from "contexts/Auth0Context";
import { isBefore, isAfter } from "date-fns";
import type { Columns } from "ui";
import * as S from "../styled";
import { EntityTransaction } from "types";

// TODO Sync with Transaction Type in BTC and ETH
export type SummaryTransaction = {
  address: string;
  entityTag: string;
  entityType: string;
  time?: Date;
  amount: number;
  value: number;
  curHop: number;
  direction: string;
  addresses: string[];
  txCount: number;
  firstTx: string;
  lastTx: string;
};

type Props = {
  title?: string;
  direction: "incoming" | "outgoing";
  transactions: EntityTransaction[];
  isLargeAddress?: boolean;
  hideAmounts: boolean;
  chain?: "btc" | "eth" | "erc20";
  originAddress: string;
  onTxCountClick?: (
    entityTag: string,
    addresses: string[],
    hop: number,
    isBreakdown: boolean,
    firstTx: string,
    lastTx: string,
  ) => void;
};

type TransactionSummaryRow = SummaryTransaction & { id: number };

const TransactionSummary = ({ transactions, isLargeAddress, hideAmounts, chain = "btc", onTxCountClick }: Props) => {
  const { user } = useContext(AuthContext);
  const hiddenTags = ["Unknown", "Unknown Addresses", "Returning Funds"];
  const systemTags = ["Unknown", "Unknown Address", "Unknown Addresses", "Returning Funds", "Insignificant Amounts"];
  const [rows, setRows] = useState<TransactionSummaryRow[]>([] as TransactionSummaryRow[]);
  const [showBreakdown, setShowBreakdown] = useState(false);
  const [showIndirect, setShowIndirect] = useState(false);
  const [showUnknownEntities, setShowUnknownEntities] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalValue, setTotalValue] = useState(0);
  const defaultColumns = getColumns({ showBreakdown, hideAmounts, onTxCountClick, chain });
  const [columns, setColumns] = useState<Columns>(defaultColumns);

  const showIndirectChange = () => {
    setShowIndirect((prev) => !prev);
  };

  const showBreakdownChange = () => {
    setShowBreakdown((prev) => !prev);
  };

  const showAllChange = () => {
    setShowUnknownEntities((prev) => !prev);
  };

  useEffect(() => {
    const curColumns: Columns = [...defaultColumns];

    setColumns(curColumns);

    // Sum Transactions by entityTag and curHop

    const transactionSummary = transactions.reduce((prev: { [entityName: string]: SummaryTransaction }, next) => {
      if ((showIndirect && !next.entityType && !next.endOfHop) || (!showIndirect && next.curHop > 1)) {
        return prev;
      }
      // const entityTag = showBreakdown ? next.address : next.entityTag;

      const entityTag =
        showBreakdown || next.entityTag === "Unknown"
          ? next.address + next.curHop
          : (next.entityTag || "Unknown") + next.curHop;

      // TODO This conditional operation returns the same value whether the condition is "true" or "false".sonarlint(typescript:S3923)
      const firstTx = chain === "eth" || chain === "erc20" ? next.firstDate : next.firstDate;
      const lastTx = chain === "eth" || chain === "erc20" ? next.lastDate : next.lastDate;

      if (entityTag in prev) {
        prev[entityTag].amount += next.amount;
        prev[entityTag].value += next.value;
        prev[entityTag].txCount += next.txCount;
        prev[entityTag].addresses = prev[entityTag].addresses.includes(next.address)
          ? prev[entityTag].addresses
          : [...prev[entityTag].addresses, next.address];
        // ? prev[entityTag].txCount + next.txs.length
        // : prev[entityTag].txCount + 1;
        if (isAfter(new Date(prev[entityTag].firstTx), new Date(firstTx))) {
          prev[entityTag].firstTx = firstTx;
        }
        if (isBefore(new Date(prev[entityTag].lastTx), new Date(lastTx))) {
          prev[entityTag].lastTx = lastTx;
        }
      } else {
        prev[entityTag] = {
          ...next,
          addresses: [next.address],
          firstTx,
          lastTx,
          // txCount: next.txs ? next.txs.length : 1,
          txCount: next.txCount,
          entityTag:
            showBreakdown &&
            (!next.entityTag || systemTags.some((unknownEntityTag) => next.entityTag.includes(unknownEntityTag)))
              ? "Unknown"
              : next.entityTag,
        };
      }

      return prev;
    }, {});
    // const destinationData = transactions;
    let curRows: TransactionSummaryRow[] = Object.keys(transactionSummary).map((entityName, index) => {
      const isKnownEntity =
        transactionSummary[entityName].entityTag && transactionSummary[entityName].entityTag !== "" ? true : false;
      return {
        id: index,
        ...transactionSummary[entityName],
        entityTag: isKnownEntity ? transactionSummary[entityName].entityTag : showBreakdown ? "Unknown" : "Unknown",
      };
    });

    if (!showUnknownEntities) {
      curRows = curRows.filter((row) => !hiddenTags.includes(row.entityTag) && !row.entityTag.startsWith("Unknown"));
    }

    // Sum total amount and total value from curRows
    const { amountSum, valueSum } = curRows.reduce(
      (previousValue, currentValue) => {
        return {
          amountSum: previousValue.amountSum + currentValue.amount,
          valueSum: previousValue.valueSum + currentValue.value,
        };
      },
      {
        amountSum: 0,
        valueSum: 0,
      },
    );

    setRows(curRows);
    if (amountSum === 0 && totalAmount === 0) {
      setShowUnknownEntities(true);
    }
    setTotalAmount(amountSum);
    setTotalValue(valueSum);
  }, [showBreakdown, showUnknownEntities, showIndirect, transactions, hideAmounts]);

  return (
    <Grid container rowSpacing={1}>
      <Grid container item xs={12} md columnSpacing={2}>
        {!hideAmounts && (
          <Grid item>
            <Tooltip disableHoverListener={chain !== "eth"} title="This figure does not include gas fees on sent funds">
              <LabelValue
                value={formatCryptoCurrencyValue(totalAmount, chain) || "0.000"}
                label="Total Amount:"
                direction="row"
                valueBold={true}
              />
            </Tooltip>
          </Grid>
        )}

        <Grid item>
          <LabelValue
            value={`$ ${formatFiatValue(totalValue, chain)}`}
            label="Total Value:"
            direction="row"
            valueBold={true}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} md="auto" columnSpacing={2}>
        {!isLargeAddress && (
          <Grid item>
            <Tooltip placement="top" title="Toggle between direct and indirect counterparties">
              <span>
                <FormControlLabel
                  control={<Switch size="small" checked={showIndirect} onChange={showIndirectChange} />}
                  labelPlacement="start"
                  label={showIndirect ? <b>Indirect</b> : "Direct"}
                  sx={{
                    marginRight: 0,
                    width: "110px",
                  }}
                />
              </span>
            </Tooltip>
          </Grid>
        )}

        <Grid item>
          <Tooltip placement="top" title="Display all addresses of all sources and destinations">
            <span>
              <FormControlLabel
                control={<Switch size="small" checked={showBreakdown} onChange={showBreakdownChange} />}
                label="Breakdown"
                sx={{
                  fontSize: "14px",
                  marginRight: "0px",
                }}
              />
            </span>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip placement="top" title="Lists all entities and clusters, known and unknown">
            <span>
              <FormControlLabel
                control={<Switch size="small" checked={showUnknownEntities} onChange={showAllChange} />}
                label="All Entities"
                sx={{
                  fontSize: "14px",
                  marginRight: "0px",
                  width: "120px",
                }}
              />
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <S.Table
          fontSize="small"
          columns={columns}
          allowExport={user.isAdmin}
          rows={rows}
          initialState={{
            sorting: {
              sortModel: [{ field: "value", sort: "desc" }],
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default TransactionSummary;
