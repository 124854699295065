import { Link } from "@mui/material";
import { useContext } from "react";
import AppContext from "contexts/AppContext";
import { Entity } from "types";
import EntityProfile from "../EntityProfile";
import * as S from "./styled";
import { HackDetail, SanctionDetail } from "pages";
import type { Hack, Sanction } from "types/Events";

type Props = {
  name?: string;
};

const EntityName = ({ name }: Props) => {
  const { entities, events, openSearchResult } = useContext(AppContext);

  const showDetail = (item: Hack | Sanction | Entity, type: "sanction" | "hack" | "entity") => {
    switch (type) {
      case "sanction":
        return openSearchResult(<SanctionDetail sanctionId={item.id} />, (item as Sanction).Subject);
      case "hack":
        return openSearchResult(<HackDetail hackId={item.id} />, (item as Hack)["Event Description"]);
      case "entity":
        return openSearchResult(<EntityProfile entity={item as Entity} />, (item as Entity).name);
    }
  };

  const findEventOrEntity = (
    name: string,
  ): {
    item: Hack | Sanction | Entity;
    type: "sanction" | "hack" | "entity";
  } | null => {
    const knownSanctionsEvent = events.sanctions.find((sanction) => {
      const sanctionsTagsProperty = sanction["On-Chain Tags"];
      if (!sanctionsTagsProperty) {
        return false;
      }
      const sanctionsTags = sanctionsTagsProperty.split(";").map((tag) => tag.trim());
      return sanctionsTags.includes(name);
    });

    if (knownSanctionsEvent) {
      return { item: knownSanctionsEvent, type: "sanction" };
    }

    const knownHackEvent = events.hacks.find((hack) => hack["Event Description"] === name);

    if (knownHackEvent) {
      return { item: knownHackEvent, type: "hack" };
    }

    const knowEntity = entities.find((entity) => entity.name === name);
    if (knowEntity) {
      return { item: knowEntity, type: "entity" };
    }
    return null;
  };

  const knownEntityOrEvent = name ? findEventOrEntity(name) : null;
  if (!knownEntityOrEvent) {
    return <strong>{name || "Unknown"}</strong>;
  }

  return (
    <S.EntityName>
      <Link onClick={() => showDetail(knownEntityOrEvent.item, knownEntityOrEvent.type)}>
        <strong>{name}</strong>
      </Link>
    </S.EntityName>
  );
};

export default EntityName;
