import { Divider, Link } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Metadata, Section, Summary } from "../../../components";
import { Scam } from "../../../types/Events";
import { TokenTags } from "../TokensTags";
import { formatFiatValue } from "utils";
import { useParams } from "react-router-dom";
import { Grid, Label, Tag, Title } from "ui";

const ScamDetail = () => {
  const [details, setDetails] = useState<Scam>();
  const { scamId } = useParams();

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(`/api/entities/scams/${scamId}`);
      setDetails(data);
    })();
  }, [scamId]);

  return (
    <Grid container direction="column" rowGap={4}>
      <Grid container rowGap={2} direction="column">
        <Grid>
          <Tag color="orange" label="Scam" backgroundColor="lightOrange" />
        </Grid>
        <Grid>
          <Title>{details?.["Event Description"]}</Title>
          <Label>
            {details &&
              `${new Date(details["Start Date"]).toLocaleString("en-gb", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })} - ${new Date(details["End Date"]).toLocaleString("en-gb", {
                month: "long",
                day: "numeric",
                year: "numeric",
              })}`}
          </Label>
        </Grid>
      </Grid>
      <Metadata
        list={[
          {
            title: "Entity",
            value: details?.["Entity"],
          },
          {
            title: "Type",
            value: details?.["Type"],
          },

          {
            title: "Jurisdiction",
            value: details?.Jurisdiction && details?.["Jurisdiction"],
          },
          {
            title: "Tokens",
            value: <TokenTags tokens={details?.Tokens} />,
          },
          {
            title: "Est. Amount",
            value: details && details["Est. Amount"] ? `$${formatFiatValue(details["Est. Amount"] as number)}` : "",
          },
          {
            title: "Perpetrator(s)",
            value: details?.["Perpetrator(s)"] && details?.["Perpetrator(s)"].split(","),
          },
        ]}
      />
      <Divider />

      <Summary text={details?.Synopsis ?? ""} showAll={true} />

      {details?.Link && (
        <Section>
          <Link href={details?.Link} target="_blank">
            View Source
          </Link>
        </Section>
      )}
    </Grid>
  );
};

export default ScamDetail;
