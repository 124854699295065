import { AppContext } from "contexts";
import { useContext } from "react";
import type { Columns } from "ui";
import { formatCryptoCurrencyValue, formatDate, formatFiatValue, getTypeToDisplay } from "utils";
import { Address, EntityName, Tags } from "components";
import { Link as MUILink } from "@mui/material";

type Props = {
  showBreakdown: boolean;
  hideAmounts: boolean;
  chain: "btc" | "eth" | "erc20";
  onTxCountClick?: (
    entityTag: string,
    addresses: string[],
    curHop: number,
    showBreakdown: boolean,
    firstTx: string,
    lastTx: string,
  ) => void;
};

const getColumns = ({ showBreakdown, hideAmounts, onTxCountClick, chain }: Props): Columns => {
  const { entityTypes } = useContext(AppContext);

  const columns: Columns = [
    {
      field: "entityTag",
      headerName: "Tag",
      flex: 1,
      renderCell: (params) => {
        return <EntityName name={params.value} />;
      },
    },
    {
      field: "entityType",
      headerName: "Type",
      width: 180,
      renderCell: (params) => {
        const typeToDisplay = getTypeToDisplay(entityTypes, params.row);
        return params.value ? (
          <Tags
            tags={[
              {
                type: typeToDisplay,
              },
            ]}
            short
          />
        ) : (
          "Unknown"
        );
      },
    },
    {
      field: "value",
      headerName: "Value ($)",
      align: "left",
      minWidth: 95,
      valueFormatter: (params) => formatFiatValue(params.value, chain),
    },
    {
      field: "firstTx",
      headerName: "First Tx",
      align: "left",
      valueFormatter: (params) => formatDate(params.value),
      valueGetter: (params) => {
        return new Date(params.row.firstTx);
      },
    },
    {
      field: "lastTx",
      headerName: "Last Tx",
      align: "left",
      valueFormatter: (params) => formatDate(params.value),
      valueGetter: (params) => {
        return new Date(params.row.lastTx);
      },
    },
    {
      field: "txCount",
      headerName: "Tx Count",
      align: "left",
      renderCell: (params) => {
        return onTxCountClick ? (
          <MUILink
            sx={{
              cursor: "pointer",
            }}
            onClick={() => {
              return onTxCountClick(
                params.row.entityTag,
                params.row.addresses,
                params.row.curHop,
                showBreakdown,
                params.row.firstTx,
                params.row.lastTx,
              );
            }}
          >
            {params.value}
          </MUILink>
        ) : (
          params.value
        );
      },
    },
    { field: "curHop", headerName: "Hops", maxWidth: 70 },
  ];

  if (showBreakdown) {
    columns.unshift({
      field: "address",
      headerName: "Address",
      width: 100,
      renderCell: ({ value }) => {
        const link = `/report/${chain === "erc20" ? "eth" : chain}/${value}`;
        return value ? <Address link={link} address={value?.toString()} /> : "";
      },
    });
  }

  if (!hideAmounts) {
    columns.splice(showBreakdown ? 3 : 2, 0, {
      field: "amount",
      headerName: "Amount",
      align: "left",
      width: 100,
      valueFormatter: (params) => formatCryptoCurrencyValue(params.value, chain),
    });
  }

  return columns;
};

export default getColumns;
