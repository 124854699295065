import { Grid } from "ui";
import { ReportTitle } from "./";
import type { AddressEntity } from "types/ethereum";

type Props = AddressEntity & {
  reportType: "btc" | "eth";
  address: string;
  addressRelatedTags: { type: string }[];
  labels: string[];
  highRiskCount: number;
  cryptoBalance: number;
  isInPortfolio: boolean;
  addToPortfolio: (portfolioId: number) => void;
  removeFromPortfolio: (portfolioId: number, address: string) => void;
};

export const ReportHeader = ({
  reportType,
  entityName,
  entityTag,
  entitySubTag,
  controllerTag,
  controllerType,
  controllerSubType,
  address,
  labels,
  addToPortfolio,
  removeFromPortfolio,
  highRiskCount,
  cryptoBalance,
  isInPortfolio,
}: Props) => (
  <Grid item xs container flexWrap="nowrap" rowSpacing={3}>
    <Grid container item xs={12} rowSpacing={4}>
      <Grid item xs={12} container alignItems="center" columnSpacing={2}>
        <Grid container item xs paddingBottom={7} flexWrap="nowrap">
          <ReportTitle
            entityName={entityName}
            entityTag={entityTag}
            entitySubTag={entitySubTag}
            controllerTag={controllerTag}
            controllerType={controllerType}
            controllerSubType={controllerSubType}
            address={address}
            addToPortfolio={addToPortfolio}
            removeFromPortfolio={removeFromPortfolio}
            reportType={reportType}
            balanceCrypto={cryptoBalance}
            balanceFiat={123123}
            highRiskCount={highRiskCount}
            labels={labels}
            isInPortfolio={isInPortfolio}
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default ReportHeader;
