import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title, SubTitle, ChartOptions } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Grid, Title as TitleUI, Subtitle as SubtitleUI, Label } from "ui";
import { formatFiatValue, convertTokenToDecimal, formatCryptoCurrencyValue } from "utils";
import type { EntityReserves } from "types";

ChartJS.register(ArcElement, Tooltip, Legend, Title, SubTitle);

type Props = {
  reserves: EntityReserves;
  entityName: string;
  print: boolean;
  isSmallScreen: boolean;
};

interface ExchangeTokens {
  [name: string]: string | string[];
}

export const Reserves = ({ reserves, entityName, isSmallScreen, print }: Props) => {
  const otherTokenLabels = [
    "CHSB",
    "BUSD",
    "CRO",
    "GUSD",
    "HBTC",
    "HT",
    "KCS",
    "NEXO",
    "SHIB",
    "DAI",
    "UNI",
    "WBTC",
    "LINK",
    "APE",
    "TUSD",
    "CRV",
    "HNT",
    "COMP",
    "BAT",
    "1INCH",
    "BAL",
    "YFI",
    "ELON",
    "SUSHI",
    "MATIC",
    "LEO",
    "QNT",
    "MANA",
    "AAVE",
    "SAND",
    "THETA",
    "AXS",
    "MKR",
    "HEX",
    "WETH",
    "GALA",
    "ZIL",
    "ENJ",
  ];
  let separateTokensLabels = ["USDC", "USDT"];
  const exchangeTokens: ExchangeTokens = {
    SwissBorg: "CHSB",
    Binance: "BUSD",
    Bybit: "BIT",
    Nexo: "NEXO",
    KuCoin: "KCS",
    HTX: ["HT", "HBTC", "HBETH"],
    Gemini: "GUSD",
    "Crypto.com": "CRO",
    "Gate.io": "GT",
    Bitget: "BGB",
    Bitfinex: "LEO",
    WooX: "WOO",
  };

  const balancesData: number[] = [];
  const balancesLabels: string[] = [];
  const { eth, btc } = reserves;

  if (btc.totalFiat > 0) {
    balancesData.push(btc.totalFiat);
    balancesLabels.push("BTC");
  }
  if (eth.totalFiat > 0) {
    balancesData.push(eth.totalFiat);
    balancesLabels.push("ETH");
  }

  const exchangeToken = exchangeTokens[entityName];
  if (exchangeToken) {
    if (Array.isArray(exchangeToken)) {
      separateTokensLabels = separateTokensLabels.concat(exchangeToken);
    } else {
      separateTokensLabels.push(exchangeToken);
    }
  }

  separateTokensLabels.forEach((tokenLabel) => {
    const token = eth.erc20[tokenLabel];
    if (token) {
      balancesData.push(token.fiat);
      balancesLabels.push(tokenLabel);
    }
  });

  const totalValueOfOther = otherTokenLabels.reduce(
    (acc, symbol) => (separateTokensLabels.includes(symbol) ? acc : acc + (eth.erc20[symbol]?.fiat || 0)),
    0,
  );

  balancesData.push(totalValueOfOther);
  balancesLabels.push("Other");

  const totalUnclassifiedFiat = eth.unclassifiedFiat + btc.unclassifiedFiat + eth.erc20UnclassifiedFiat;
  const totalHotFiat = eth.hotFiat + btc.hotFiat + eth.erc20HotFiat;
  const totalColdFiat = eth.coldFiat + btc.coldFiat + eth.erc20ColdFiat;
  const hasUnclassified = totalUnclassifiedFiat > 0;

  // Only show unclasified if there are some unclassified funds and there are hot or cold funds
  const hotColdUnclassifiedData = [];
  const hotColdUnclassifiedDataSetLabels = [];
  if (totalHotFiat > 0) {
    hotColdUnclassifiedData.push(totalHotFiat);
    hotColdUnclassifiedDataSetLabels.push("Hot Wallets");
  }

  if (totalColdFiat > 0) {
    hotColdUnclassifiedData.push(totalColdFiat);
    hotColdUnclassifiedDataSetLabels.push("Cold Wallets");
  }

  if (hasUnclassified && (totalHotFiat > 0 || totalColdFiat > 0)) {
    hotColdUnclassifiedDataSetLabels.push("Unclassified Wallets");
    hotColdUnclassifiedData.push(totalUnclassifiedFiat);
  }

  const greyRGBA = "rgba(128, 128, 128, 0.8)";
  const hotColdUnclassifiedBalancesColors = ["rgba(249, 29, 66)", "rgba(1, 1, 192, 0.8)", greyRGBA];

  const allTokenBalancesColors = [
    "RGB(255, 107, 219)",
    "RGB(219, 201, 127)",
    "RGB(144, 186, 214)",
    "RGB(127, 200, 243)",
    "RGB(104, 0, 190)",
    "RGB(240, 149, 149)",
    "RGB(219, 195, 161)",
    "RGB(255, 184, 127)",
    "RGB(243, 149, 149)",
    "RGB(255, 144, 144)",
    "RGB(155, 219, 203)",
    "RGB(144, 215, 214)",
    "RGB(219, 211, 149)",
    "RGB(146, 255, 184)",
    "RGB(214, 173, 215)",
  ];

  const balancesColors = allTokenBalancesColors.slice(0, balancesLabels.length);

  // const backgroundColors = [...balancesColors, ...hotColdBalancesColors];

  const totalBalanceData = {
    layout: {
      padding: 0,
    },
    labels: [...balancesLabels, ...hotColdUnclassifiedDataSetLabels],
    datasets: [
      {
        label: "Balance",
        labels: [...balancesLabels],
        data: balancesData,
        backgroundColor: balancesColors,
        hoverBackgroundColor: balancesColors,
      },
      {
        label: "Hot Cold Unclassified",
        labels: hotColdUnclassifiedDataSetLabels,
        data: hotColdUnclassifiedData,
        backgroundColor: hotColdUnclassifiedBalancesColors,
        hoverBackgroundColor: hotColdUnclassifiedBalancesColors,
      },
    ],
  };
  const options: ChartOptions<"doughnut"> = {
    animation: print,
    plugins: {
      title: {
        display: false,
      },

      legend: {
        position: isSmallScreen ? "top" : "right",
        title: {
          display: true,
        }, // or whatever number
        display: true,
        labels: {
          usePointStyle: true,
          boxWidth: 8,
          padding: 10,
          generateLabels: (chart) => {
            const labels = chart.data.labels as string[];
            const datasets: any[] = chart.data.datasets;
            return labels.map((label: string, i: number) => {
              const datasetIndex = datasets.findIndex((dataset) => dataset.labels.includes(label));

              return {
                text: "  " + label, // Hack to add space between box and text
                lineWidth: 0,
                textAlign: "left",
                fillStyle: datasets[datasetIndex].backgroundColor[datasets[datasetIndex].labels.indexOf(label)],
                index: i,
                datasetIndex: datasetIndex,
              };
            });
          },
        },
      },
      tooltip: {
        callbacks: {
          title: (tooltipItem) => {
            const label = totalBalanceData.datasets[tooltipItem[0].datasetIndex].labels[tooltipItem[0].dataIndex];
            return label;
          },

          label: (tooltipItem) => {
            const result: string[] = [];
            const label = totalBalanceData.datasets[tooltipItem.datasetIndex].labels[tooltipItem.dataIndex];
            const fiatValue = ` $${formatFiatValue(
              totalBalanceData.datasets[tooltipItem.datasetIndex].data[tooltipItem.dataIndex],
            )}`;

            result.push(fiatValue);

            switch (label) {
              case "ETH":
                result.push("         ETH: " + formatCryptoCurrencyValue(eth.total, "eth"));
                break;

              case "BTC":
                result.push(" BTC: " + formatCryptoCurrencyValue(btc.total, "btc"));
                break;
              case "Cold Wallets":
                result.push(
                  ...[
                    " BTC: $" + formatFiatValue(btc.coldFiat),
                    " ETH: $" + formatFiatValue(eth.coldFiat),
                    " ERC20: $" + formatFiatValue(eth.erc20ColdFiat),
                  ],
                );
                break;

              case "Hot Wallets":
                result.push(
                  ...[
                    " BTC : $" + formatFiatValue(btc.hotFiat),
                    " ETH : $" + formatFiatValue(eth.hotFiat),
                    " ERC20: $" + formatFiatValue(eth.erc20HotFiat),
                  ],
                );
                break;

              case "Unclassified Wallets":
                result.push(
                  ...[
                    " BTC: $" + formatFiatValue(btc.unclassifiedFiat),
                    " ETH: $" + formatFiatValue(eth.unclassifiedFiat),
                    " ERC20: $" + formatFiatValue(eth.erc20UnclassifiedFiat),
                  ],
                );
                break;

              default:
                if (eth.erc20[label]) {
                  result.push(
                    ` ${label}: ${formatCryptoCurrencyValue(
                      convertTokenToDecimal(eth.erc20[label].balance, eth.erc20[label].decimals),
                      "erc20",
                    )}`,
                  );
                }
            }

            return result;
          },
        },
      },
    },
  };

  return (
    <Grid
      container
      direction="column"
      style={{ breakInside: "avoid" }}
      rowGap={2}
      alignItems={isSmallScreen ? "center" : "left"}
    >
      <Grid container direction="column" alignItems={"flex-start"} rowGap={1}>
        <Grid item>
          <SubtitleUI>Proof of Assets</SubtitleUI>
        </Grid>
        <Grid item>
          <TitleUI textAlign="left">
            $
            {formatFiatValue(
              eth.totalFiat + btc.totalFiat + eth.erc20HotFiat + eth.erc20ColdFiat + eth.erc20UnclassifiedFiat,
            )}
          </TitleUI>
          <Label>Wallets Assessed: {reserves.numberOfWallets}</Label>
        </Grid>
      </Grid>
      <Grid container item width={print ? "400px" : "100%"} minWidth="450px" minHeight="450px">
        <Doughnut data={totalBalanceData} options={options} />
      </Grid>
    </Grid>
  );
};

export default Reserves;
