import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Link as MUILink } from "@mui/material";
import { Grid, Subtitle } from "ui";
import { Table } from "./styled";
import { Tags, EntityName, Address } from "components";
import { useContext } from "react";
import AppContext from "contexts/AppContext";
import {
  capitalLetter,
  formatCryptoCurrencyValue,
  formatDate,
  formatFiatValue,
  getEntityToDisplay,
  getTypeToDisplay,
} from "utils";
import AuthContext from "contexts/Auth0Context";
import { Transaction } from "types";
import { TransactionWithID } from "../../pages/Portfolio/HighRiskTable/columns";
import { TokenTags } from "../../pages";

type Props = {
  transactions: Transaction[];
  chain?: "btc" | "eth" | "erc20";
};

export const HighRiskTransactions = ({ transactions, chain = "btc" }: Props) => {
  const { entityTypes } = useContext(AppContext);
  const { user } = useContext(AuthContext);

  const groupedTransactions: TransactionWithID[] = transactions.map((transaction: Transaction, index) => {
    return {
      ...transaction,
      id: index,
    };
  });

  const columns: GridColDef[] = [
    {
      field: "address",
      headerName: "Address",
      width: 95,
      renderCell: ({ value }) => <Address link={`/report/${chain}/${value}`} address={value} />,
    },
    {
      field: "entityTag",
      headerName: "Tag",
      flex: 1,
      renderCell: (params: GridRenderCellParams<string, TransactionWithID>) => {
        const { entityTag } = getEntityToDisplay(params.row, entityTypes);
        return <EntityName name={entityTag} />;
      },
    },
    {
      field: "entityType",
      headerName: "Type",
      width: 120,
      renderCell: (params: GridRenderCellParams<string, TransactionWithID>) => {
        const type = getTypeToDisplay(entityTypes, {
          entityType: params.row.entityType,
          entitySubType: params.row.entitySubType ?? null,
          controllerType: params.row.controllerType ?? null,
          controllerSubType: params.row.controllerSubType ?? null,
        });
        return type ? <Tags tags={[{ type }]} /> : null;
      },
    },
    {
      field: "date",
      headerName: "Date",
      width: 100,
      valueFormatter: (params) => (params.value ? formatDate(params.value as string) : null),
    },
    {
      field: "amount",
      headerName: "Amount",
      align: "left",
      width: 115,
      valueFormatter: ({ value }) => formatCryptoCurrencyValue(value, chain),
    },
    {
      field: "value",
      headerName: "Value ($)",
      type: "number",
      align: "left",
      width: 95,
      valueFormatter: ({ value }) => formatFiatValue(value, chain),
    },
    {
      field: "symbol",
      headerName: "Asset",
      align: "left",
      width: 75,
      renderCell: ({ value }: GridRenderCellParams<string, TransactionWithID>) => {
        return <TokenTags tokens={value ? [value] : []} />;
      },
    },
    {
      field: "direction",
      headerName: "Direction",
      align: "left",
      width: 95,
      valueFormatter: ({ value }) => capitalLetter(value),
    },
    {
      field: "hop",
      width: 65,
      headerName: "Hops",
    },
    {
      field: "hash",
      headerName: "Hash",
      width: 80,
      type: "string",
      renderCell: ({ value }) => (
        <MUILink
          href={
            chain === "eth" || chain === "erc20"
              ? `https://etherscan.io/tx/${value}`
              : `https://www.blockchain.com/${chain}/tx/${value}`
          }
          target="_blank"
          rel="noreferrer"
        >
          {value.slice(0, 5)}...
        </MUILink>
      ),
    },
  ];

  return (
    <Grid container rowSpacing={2} direction="column">
      <Grid item container justifyContent="left">
        <Subtitle>High Risk Transactions</Subtitle>
      </Grid>
      <Grid item>
        <Table
          allowExport={user.isAdmin}
          fontSize="small"
          columns={columns}
          rows={groupedTransactions}
          initialState={{
            sorting: {
              sortModel: [{ field: "amount", sort: "desc" }],
            },
          }}
        />
      </Grid>
    </Grid>
  );
};
