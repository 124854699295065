import { useContext } from "react";
import AppContext from "contexts/AppContext";
import { Grid } from "ui";
import * as S from "./styled";
import type { EntityType, EntityTypeRisk } from "types";

type TagInformation = { type: string; subtype?: string | null; labels?: string[] };

type Props = {
  tags: TagInformation[];
  short?: boolean;
  noColor?: boolean;
  showRelevantTag?: boolean;
  justifyContent?: "flex-start" | "center" | "flex-end" | "space-between";
  tagMargin?: string;
};

const tagsProfileOrder: EntityTypeRisk[] = ["LOW", "VERY_HIGH", "HIGH", "MEDIUM"];

const checkTagWithProfile = (tag: TagInformation, profileTags: EntityType[]) => {
  for (const profileTag of profileTags) {
    if (tag.type?.includes(profileTag.name)) {
      return tag.type;
    }
    if (tag.subtype?.includes(profileTag.name)) {
      return tag.subtype;
    }
  }

  return null;
};

const filterTagsByRisk = (tags: EntityType[], risk: EntityTypeRisk) => {
  return tags.filter((type) => type.risk === risk);
};

const sortTagsByRisk = (typeA: { risk: EntityTypeRisk }, typeB: { risk: EntityTypeRisk }) => {
  const aIndex = tagsProfileOrder.indexOf(typeA.risk);
  const bIndex = tagsProfileOrder.indexOf(typeB.risk);

  return aIndex - bIndex;
};

export const Tags = ({ tags, short, noColor, showRelevantTag, justifyContent, tagMargin }: Props) => {
  const { entityTypes } = useContext(AppContext);
  const veryHighRiskTags = filterTagsByRisk(entityTypes, "VERY_HIGH");
  const highRiskTags = filterTagsByRisk(entityTypes, "HIGH");
  const mediumRiskTags = filterTagsByRisk(entityTypes, "MEDIUM");

  const tagsWithRiskProfile = tags
    .filter((tag) => tag.type)
    .map((tag) => {
      let risk: EntityTypeRisk = "LOW";
      let relevantTag = checkTagWithProfile(tag, mediumRiskTags);
      if (relevantTag) {
        risk = "MEDIUM";
      } else {
        relevantTag = checkTagWithProfile(tag, highRiskTags);
        if (relevantTag) {
          risk = "HIGH";
        } else {
          relevantTag = checkTagWithProfile(tag, veryHighRiskTags);
          if (relevantTag) {
            risk = "VERY_HIGH";
          }
        }
      }

      return {
        tag,
        relevantTag: showRelevantTag && relevantTag ? relevantTag : tag.type,
        risk,
      };
    })
    .sort(sortTagsByRisk);

  return (
    <Grid item container justifyContent={justifyContent} rowGap={1}>
      {tagsWithRiskProfile.map(({ tag, relevantTag, risk }, index) => {
        const label = short || !tag.subtype ? relevantTag : `${relevantTag} - ${tag.subtype}` || "Unknown";

        if (label === "Unknown") {
          return <span key={`unknown_${index}`}>{label}</span>;
        }

        return (
          <S.Chip
            size="small"
            key={tag.type}
            label={label}
            $profile={risk}
            icon={risk === "HIGH" ? <S.ErrorIcon $noColor={noColor} /> : undefined}
            sx={{
              marginRight: "5px",
              marginTop: tagMargin,
            }}
            $noColor={noColor}
          />
        );
      })}
    </Grid>
  );
};
