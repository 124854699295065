import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { AppProvider } from "contexts/AppContext";
import React from "react";
import { useNavigate } from "react-router-dom";
import { AuthProvider } from "./contexts/Auth0Context";
import ErrorBoundary from "./ErrorBoundary";
import Routes from "./routes";
import "./App.css";

const App: React.FC = () => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.targetUrl || window.location.pathname);
  };

  return (
    <ErrorBoundary>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN!}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "read:current_user update:current_user_metadata profile email openid",
        }}
        onRedirectCallback={onRedirectCallback}
      >
        <AuthProvider>
          <AppProvider>
            <Routes />
          </AppProvider>
        </AuthProvider>
      </Auth0Provider>
    </ErrorBoundary>
  );
};
export default App;
