import React, { createContext, useState, useEffect, useContext } from "react";
import { ModalDialog, Loading } from "ui";
import { Entity } from "types";
import axios, { AxiosError } from "axios";
import { useErrorHandler } from "hooks";
import AuthContext from "./Auth0Context";
import type { Portfolio, EntityType } from "types";
import { Hack, Sanction, Scam } from "types/Events";
import Report from "types/Report";

import * as Ethereum from "types/ethereum";
type Props = {
  children: React.ReactNode;
};

export type AppContextType = {
  openSearchResult: (item: React.ReactNode, title?: string) => void;
  updatePortfolios: (portfolios: Portfolio[]) => void;
  savePortfolio: (portfolioName: string) => void;
  addToPortfolio: (portfolioId: number, report: Report | Ethereum.Report) => void;
  removeFromPortfolio: (portfolioId: number, address: string) => void;
  entities: Entity[];
  events: {
    hacks: Hack[];
    sanctions: Sanction[];
    scams: Scam[];
  };
  entityTypes: EntityType[];
  addressLabels: EntityLabel[];
  portfolios: Portfolio[];
  hasNewNotifications: boolean;
  setHasNewNotifications: React.Dispatch<React.SetStateAction<boolean>>;
};

type EntityLabel = {
  name: string;
};

type EntitiesResponse = {
  entities: Entity[];
  types: EntityType[];
  labels: EntityType[];
};

export const AppContext: React.Context<AppContextType> = createContext<AppContextType>({
  /* eslint-disable */
  openSearchResult: () => {},
  updatePortfolios: () => {},
  addToPortfolio: () => {},
  removeFromPortfolio: () => {},
  savePortfolio: () => {},
  entities: [],
  events: {
    hacks: [],
    sanctions: [],
    scams: [],
  },
  entityTypes: [],
  addressLabels: [],
  portfolios: [],
  hasNewNotifications: false,
  setHasNewNotifications: () => {},
});

export const AppProvider = ({ children }: Props) => {
  const [searchResult, setSearchResult] = useState<React.ReactNode>(null);
  const [entities, setEntities] = useState<Entity[]>([]);
  const [events, setEvents] = useState<{
    hacks: Hack[];
    sanctions: Sanction[];
    scams: Scam[];
  }>({ hacks: [], sanctions: [], scams: [] });
  const [entityTypes, setEntityTypes] = useState<EntityType[]>([]);
  const [addressLabels, setAddressLabels] = useState<EntityLabel[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [portfolios, setPortfolios] = useState<Portfolio[]>([]);
  const [hasNewNotifications, setHasNewNotifications] = useState<boolean>(false);
  const { user } = useContext(AuthContext);

  const handleError = useErrorHandler();
  const openSearchResult = (item: React.ReactNode): void => {
    setSearchResult(item);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoaded(false);
      try {
        const [
          entitiesResponse,
          portfolioResponse,
          hacksResponse,
          sanctionsResponse,
          scamsResponse,
          hasNewNotificationsResponse,
        ] = await Promise.all([
          axios.get<EntitiesResponse>("/api/entities"),
          axios.get("/api/portfolio"),
          axios.get("/api/entities/hacks"),
          axios.get("/api/entities/sanctions"),
          axios.get("/api/entities/scams"),
          axios.get("/api/notifications/has-new"),
        ]);

        setHasNewNotifications(hasNewNotificationsResponse.data);

        setPortfolios(portfolioResponse.data);

        let entities = entitiesResponse.data.entities;
        if (user && !user.isAdmin) {
          entities = entities.filter((entity) => {
            return entity.description && entity.description.trim() !== "";
          });
        }
        setEntities(entities);
        setEntityTypes(entitiesResponse.data.types);
        setAddressLabels(entitiesResponse.data.labels);
        setEvents({
          hacks: Array.isArray(hacksResponse.data) ? hacksResponse.data : [],
          sanctions: sanctionsResponse.data,
          scams: scamsResponse.data,
        });
      } catch (error) {
        handleError(error);
      } finally {
        setIsLoaded(true);
      }
    };

    fetchData();
  }, []);

  const removeAddressFromPortfolio = async (portfolioID: number, address: string) => {
    try {
      const updatedPortfolioData = portfolios.find((portfolio) => portfolio.id === portfolioID);

      if (!updatedPortfolioData) {
        throw Error("Portfolio not found");
      }

      await axios.delete(`/api/portfolio/${updatedPortfolioData.id}/address/${address}`);

      updatedPortfolioData.data = updatedPortfolioData?.data.filter(({ addressInfo }: Report | Ethereum.Report) => {
        return addressInfo.address.toLowerCase() !== address.toLowerCase();
      });

      const updatedPortfolios = portfolios.map((portfolio) => {
        if (portfolio.id === portfolioID) {
          return updatedPortfolioData;
        }
        return portfolio;
      });

      setPortfolios(updatedPortfolios);
    } catch (error) {
      handleError(error);
    }
  };

  const addReportToPortfolio = async (portfolioId: number, report: Report | Ethereum.Report) => {
    try {
      await axios.put(`/api/portfolio/${portfolioId}`, {
        ...report,
      });
      const updatedPortfolios: Portfolio[] = portfolios.map((portfolio) => {
        if (portfolio.id === portfolioId) {
          portfolio.data.push(report);
        }
        return portfolio;
      });
      setPortfolios(updatedPortfolios);
    } catch (error: unknown | AxiosError) {
      throw error;
    }
  };

  const savePortfolio = async (name: string) => {
    try {
      const response = await axios.post("/api/portfolio", {
        name: name,
      });
      const portfolio = response.data;
      setPortfolios([...portfolios, portfolio]);
    } catch (error: unknown | AxiosError) {
      throw error;
    }
  };

  return (
    <AppContext.Provider
      value={{
        openSearchResult: openSearchResult,
        entities: entities,
        events: events,
        entityTypes: entityTypes,
        addressLabels: addressLabels,
        portfolios,
        savePortfolio: savePortfolio,
        addToPortfolio: (portfolioId: number, report: Report | Ethereum.Report) => {
          addReportToPortfolio(portfolioId, report);
        },
        removeFromPortfolio: removeAddressFromPortfolio,
        updatePortfolios: (portfolios: Portfolio[]) => {
          setPortfolios(portfolios);
        },
        hasNewNotifications,
        setHasNewNotifications,
      }}
    >
      {!isLoaded ? <Loading /> : children}
      {searchResult && (
        <ModalDialog
          open={searchResult !== null}
          // title={searchResultTitle}
          onClose={() => {
            setSearchResult(null);
          }}
        >
          {searchResult}
        </ModalDialog>
      )}
    </AppContext.Provider>
  );
};

export default AppContext;
