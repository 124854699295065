import styled from "styled-components";
import { Avatar as AvatarMUI } from "@mui/material";

export type AvatarProps = {
  size: "extraSmall" | "small" | "normal" | "large";
  shape?: "square" | "circle";
};

const AvatarSizes: { [key: string]: string } = {
  extraSmall: "14px",
  small: "24px",
  normal: "40px",
  large: "56px",
};

export const Avatar = styled(AvatarMUI)<AvatarProps>`
  width: ${(p) => AvatarSizes[p.size]};
  height: ${(p) => AvatarSizes[p.size]};

  ${(p) => p.shape === "square" && "border-radius: 0;"}

  & .MuiAvatar-img {
    object-fit: contain;
  }
`;
